<template>
  <div>
    <el-row v-if="totalSize !== 0" class="movie-list">
      <el-col :md="18">
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>文章列表</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="refresh">刷新</el-button>
            </div>
            <div class="text item" v-for="(item, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
              <el-row>
                <el-col :md="4">
                  <router-link target="_blank" :to="`/article/${item.articleId}`">
                    <el-image
                      lazy
                      fit="cover"
                      :src="item.coverUrl"
                      class="coverImg"
                    />
                  </router-link>
                </el-col>
                <el-col :md="20">
                  <router-link target="_blank" :to="`/article/${item.articleId}`">
                    <el-row>
                      <div style="padding: 14px">
                        <span style="left: 0;margin-bottom: 0px;color: black;">{{ item.title | ellipsis }}</span>
                      </div>
                    </el-row>
                  </router-link>
                  <el-row>
                    <div style="padding: 14px">
                      <span style="left: 0;margin-bottom: 0px;color: black;">{{ item.excerpt }}</span>
                    </div>
                  </el-row>
                </el-col>
              </el-row>
              <el-row>
                <div style="padding: 14px">
                  <span style="left: 0;margin-bottom: 0px;color: black;">发布于: {{ item.publishAt }}</span>
                </div>
              </el-row>
              <el-divider />
            </div>
            <el-col :span="18" class="pagination">
              <el-pagination
                :small="screenWidth <= 768"
                layout="prev, pager, next"
                :page-size="pageSize"
                :current-page="currentPage"
                :total="totalSize"
                @current-change="handleCurrentChange"
              />
            </el-col>
          </el-card>
        </el-row>
      </el-col>
      <el-col :md="6">
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-row>
                <span>热门文章</span>
              </el-row>
            </div>
            <div class="text item">
              <el-row>
              </el-row>
            </div>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
    <el-row v-else class="not-result">
      <el-col :span="12" :offset="6">
        <img src="@/assets/img/icon/not-result.png">
        <div>没有文章数据</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getArticles} from "@/api/article";

export default {
  name: 'Article',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 50
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  components: {},
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
    }
  },
  created() {
    document.title = '文章主页'

    this.getArticlesWrapper(this.currentPage)
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getArticlesWrapper(this.currentPage)
      // 回到顶部
      scrollTo(0, 0)
    },
    getArticlesWrapper(page) {
      getArticles(page).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.list
          this.totalSize = respData.totalSize
        }
      })
    },
    refresh() {
      this.$message.info('暂未实现')
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .coverImg {
    height: 120px !important;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.coverImg {
  width: 100%;
  height: 120px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

</style>
